<template>
  <div class="navContainer">
    <div class="logo midFont" @click="$router.push('/')">
      <img src="@/assets/logo.jpeg" alt="" style="width: 40px; height: 40px" />
      &nbsp; 裕康云科技
    </div>
    <el-menu
      mode="horizontal"
      @select="handleSelect"
      :default-active="$route.path"
      style="justify-content: flex-end"
      router
    >
      <el-menu-item index="/douyin">首页 </el-menu-item>
<!--      <el-menu-item index="/financialecurity">金融科技 </el-menu-item>-->
<!--      <el-menu-item index="/healthcare">医疗科技 </el-menu-item>-->
<!--      <el-sub-menu index="/hr">-->
<!--        <template #title><div @click = "handle">职业标准</div></template>-->
<!--        <el-menu-item index="/hr/standardization">职业标准制定</el-menu-item>-->
<!--        <el-menu-item index="/hr/skillEvaluate">职业技能等级评价/认定</el-menu-item>-->
<!--        <el-menu-item index="/oneplusx">1+X证书</el-menu-item>-->
<!--&lt;!&ndash;        <el-menu-item index="/doctor">岗位胜任力测评</el-menu-item>&ndash;&gt;-->
<!--        <el-sub-menu index="/learn">-->
<!--          <template #title>学考评台</template>-->

<!--          <el-menu-item index="/learn/startTest">起点测试</el-menu-item>-->
<!--          <el-menu-item index="/learn/learn">精准学习</el-menu-item>-->
<!--          <el-menu-item index="/learn/exam">培训考试</el-menu-item>-->
<!--          <el-menu-item index="/learn/evaluate">评价认定</el-menu-item>-->
<!--          <el-menu-item index="/learn/certificateManagement">证书管理</el-menu-item>-->
<!--          <el-menu-item index="/learn/continuEducation">继续教育</el-menu-item>-->
<!--          <el-menu-item index="/learn/price">价格</el-menu-item>-->
<!--        </el-sub-menu>-->

<!--      </el-sub-menu>-->

      <el-menu-item index="/contactUs">联系我们</el-menu-item>
    </el-menu>
  </div>
  <router-view />
<!--  <div class="footer">-->
<!--    <div class="betweenFlex" style="">-->
<!--      <div class="flex2">-->
<!--        <p class="smallFont whiteFont">裕康云科技</p>-->
<!--        <p class="smallFont greyFont">Yukon Systems</p>-->
<!--        <p class="smallFont greyFont">网址：www.yukonsys.com</p>-->
<!--        <p class="smallFont greyFont">-->
<!--          地址：广东省深圳市南山区侨乡路4068号智慧广场A栋5层-->
<!--        </p>-->
<!--        <p class="smallFont greyFont">工作时间：9:00 - 18:00（工作日）</p>-->
<!--&lt;!&ndash;        <p class="smallFont greyFont">电话咨询：(0755) 8453 2874</p>&ndash;&gt;-->

<!--      </div>-->
<!--      <div class="flex1">-->

<!--&lt;!&ndash;        <p class="midFont whiteFont">电子邮箱</p>&ndash;&gt;-->
<!--        <p class="smallFont whiteFont">  (0755)  8453 2874</p>-->
<!--        <p class="smallFont whiteFont">人力资源:   13823380146</p>-->
<!--        <p class="smallFont whiteFont">医疗科技:   13823380394</p>-->
<!--        <p class="smallFont whiteFont">金融科技:   13823381740</p>-->
<!--        <p class="smallFont whiteFont">service@yukonsys.com</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="footerBottom centerFont">
    Copyright ©www.haijidejianchishenme.com<br />
    <a  href="https://beian.miit.gov.cn">备案号：粤ICP备19017406号-9</a>
  </div>
</template>
<script>
export default {
  data() {
    return { activeIndex: "1" };
  },
  mounted() {},
  methods: {
    handleSelect(...e) {
      console.log(e);
    },
    handle (){
      console.log("点了一下");
      //  路径/home对应我在router目录下index.js中定义的path属性值
      this.$router.push('/professionalStandards');
    }
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}
nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.navContainer {
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 999;
  position: fixed;
  top: 0;
  .logo {
    position: absolute;
    height: 59px;
    line-height: 59px;
    left: 40px;
    top: 0;
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.footer {
  background: rgba(34, 36, 41, 1);
  padding: 30px calc((100vw - 1280px) / 2);
  text-align: left;
}
.footerBottom {
  color: #666;
  background:#d7d8d9;
  padding: 10px;
}
.betweenFlex {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
.aroundFlex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.centerAlignFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bigFont {
  font-size: 32px;
}
.midFont {
  font-size: 24px;
}
.smallFont {
  font-size: 16px;
}
.miniFont {
  font-size: 12px;
}
.greyFont {
  color: #666;
}
.blueFont {
  color: #409eff;
}
.centerText {
  text-align: center;
}
.leftText {
  text-align: left;
}
.rightText {
  text-align: right;
}
.whiteFont {
  color: #fff;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex4 {
  flex: 4;
}
.flex5 {
  flex: 5;
}
img {
  object-fit: contain;
  width: 100%;
}
.greenFont {
  color: #3eac66;
}
a:link{
  color: #666;
}
</style>
