<template>
    <div class="totalContainer">
        <div> <img
                src="@/assets/a13.png"
                alt=""
                style="width: 100%; display: block"
        /></div>
        <div class="contentBox leftText">
            <div class="betweenFlex">
                <div class="flex1">
                    <img
                        src="@/assets/a18.jpg"
                        alt=""
                        style="width: 100%; display: block"
                    />
                </div>
                <div class="flex1">
                    <img
                        src="@/assets/a17.jpg"
                        alt=""
                        style="width: 100%; display: block"
                    />
                </div>
                <img
                    src="@/assets/a16.jpg"
                    alt=""
                    style="display: block; width: 50%; margin: auto"
                />

            </div>
            <div class="betweenFlex">
            <div class="flex1">
                <img
                    src="@/assets/a21.png"
                    alt=""
                    style="width: 100%; display: block"
                />
            </div>
            <div class="flex1">
                <img
                    src="@/assets/a19.png"
                    alt=""
                    style="width: 100%; display: block"
                />
            </div>
            <div class="flex1">
                <img
                    src="@/assets/a20.png"
                    alt=""
                    style="width: 100%; display: block"
                />
            </div>
            </div>
        </div>
<!--        <div class="contentBox leftText">-->
<!--            <div class="betweenFlex">-->
<!--                <div class="flex1">-->
<!--                    <img-->
<!--                        src="@/assets/a6.png"-->
<!--                        alt=""-->
<!--                        style="width: 100%; display: block"-->
<!--                    />-->
<!--                </div>-->
<!--                <div class="flex1">-->
<!--                    <img-->
<!--                        src="@/assets/a7.jpg"-->
<!--                        alt=""-->
<!--                        style="width: 100%; display: block"-->
<!--                    />-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="contentBox leftText">
            <div class="bigFont greenFont">网球放松</div>
            <div class="bigFont greenFont">tennis to relax</div>
            <br />
            <div class="betweenFlex">
                <div class="flex1">
                    <img
                            src="@/assets/a14.jpg"
                            alt=""
                            style="width: 100%; display: block"
                    />
                    <br />
                    <div class="midFont greenFont">网球改革</div>
                    <p class="contentFont">
                        网球的历史是一部充满变革和发展的历史。
                    </p>
                    <p class="contentFont">
                        从最初的掌中游戏到现代的草地网球。
                    </p>
                </div>
                <div class="flex1">
                    <img
                            src="@/assets/a15.jpg"
                            alt=""
                            style="width: 100%; display: block"
                    />
                    <br />
                    <div class="midFont greenFont">网球发展</div>
                    <p class="contentFont">
                        20世纪，网球在美国取得了巨大的发展，成为了世界第二大球类运动，如今，网球已经成为了一项全球性的体育运动，世界各地都有广泛的爱好者和专业选手。
                    </p>
                </div>
<!--                <div class="flex1">-->
<!--                    <img-->
<!--                            src="@/assets/ourbusiness3.jpg"-->
<!--                            alt=""-->
<!--                            style="width: 100%; display: block"-->
<!--                    />-->
<!--                    <br />-->
<!--                    <div class="midFont greenFont">SaaS服务</div>-->
<!--                    <p class="contentFont">-->
<!--                        为中小客户提供一站式人力资源晋升管理SaaS平台。包括在技术方面运用AI胜任力测评技术，在业务上深入人才培养的每个核心领域的技术应用，打造精准、高效、专业的人才管理体系。-->
<!--                    </p>-->
<!--                </div>-->
            </div>
        </div>

        <div class="betweenFlex" style="width: 70%; margin: auto">
            <div class="dividedContainer flex1">
                <div class="line"></div>
                <div class="miniFont greyFont font leftText">YUKON SYSTEMS</div>
            </div>
            <div class="dividedContainer flex1">
                <div class="miniFont greyFont font rightText">YUKON SYSTEMS</div>
                <div class="line"></div>
            </div>
        </div>
        <div class="contentBox leftText">
            <div class="bigFont greenFont">网球起源</div>
            <div class="bigFont greenFont">Origin of Tennis</div>

            <p class="aboutFont">
                网球的起源可以追溯到12-13世纪的法国。当时在法国传教士和宫廷内流传着一种“掌球”游戏，方法是在空地上两人隔着一条绳子，用手掌将布包着头发等物质制成的球互相击打。这种运动不仅在修道院中盛行，而且也出现在法国宫廷。法国国王路易十世在位时，宫廷中就经常进行这种以消遣为目的地网球运动。到了14世纪中叶，这种供贵族玩的古式网球从法国传入英国，英国爱德华第三对网球发生很大兴趣，下令在宫中修建一片室内球场。15世纪发明了穿弦的球拍，16世纪古式室内网球成为法国的国球。以后，古式室内网球有了自己的规则，在欧洲，尤其是英国得到了较好的开展。1873年，会打古式网球的英国少校M.温菲尔德，在羽毛球运动的启示下，设计了一种适用于户外的、男女都可以从事的网球运动，当时叫做司法泰克（Sphairistike，意思为击球的技术）。1875年，随着这项运动在8字形球场上风靡起来，全英槌球俱乐部在槌球场边另设了一片草地网球场,紧接着，古式网球的权威组织者玛利博恩板球俱乐部为这项运动制定了一系列规则。从此，草地网球正式取代了司法泰克。1877年，在英国伦敦郊外温布尔顿设置了几片草地网球总会，草地网球在英国得到了进一步的开展。同年7月，举办了首届草地网球锦标赛，即温布尔顿第一届比赛
            </p>
            <br />
            <div class="betweenFlex">
                <div class="flex1">
                    <img
                        src="@/assets/a22.png"
                        alt=""
                        style="width: 100%; display: block"
                    />
                </div>
                <div class="flex1">
                    <img
                        src="@/assets/a25.png"
                        alt=""
                        style="width: 100%; display: block"
                    />
                </div>
            </div>

        </div>


    </div>
</template>

<script>
</script>
<style scoped lang="less">
.dividedContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .line {
    height: 2px;
    background: #ccc;
    flex: 3;
  }
  .font {
    flex: 1;
    margin: 10px;
    transform: scale(0.8);
  }
}
.totalContainer {
  color: #333;
}
.betweenFlex .flex1:not(:last-child) {
  margin-right: 50px;
}
.contentBox {
  padding: 30px 0;
  width: 70%;
  margin: auto;
}

.centerText {
  text-align: center;
}

.contentFont {
  width: 100%;
  line-height: 1.6em;
}
.aboutFont {
  line-height: 1.6em;
  margin: 30px 0;
}
</style>