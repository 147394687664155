import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/douyin.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/douyin",
    name: "/",
    component: Home,
  },
  {
    path: "/about",
    name: "about",

    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/learn/learn",
    name: "learn",

    component: () => import("../views/learn.vue"),
  },
  {
    path: "/learn/exam",
    name: "exam",

    component: () => import("../views/exam.vue"),
  },
  {
    path: "/learn/evaluate",
    name: "evaluate",

    component: () => import("../views/evaluate.vue"),
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import("../views/contactUs.vue"),
  },
  {
    path: "/learn/price",
    name: "price",
    component: () => import("../views/price.vue"),
  },
  {
    path: "/oneplusx",
    name: "oneplusx",
    component: () => import("../views/oneplusx.vue"),
  },
  {
    path: "/hr/standardization",
    name: "standardization",
    component: () => import("../views/standardization.vue"),
  },
  {
    path: "/hr/skillEvaluate",
    name: "skillEvaluate",
    component: () => import("../views/skillEvaluate.vue"),
  },
  {
    path: "/professionalStandards",
    name: "professionalStandards",
    component: () => import("../views/professionalStandards.vue"),
  },

  {
    path: "/financialecurity",
    name: "financialecurity",
    component: () => import("../views/financialecurity.vue"),
  },
  {
    path: "/healthcare",
    name: "healthcare",
    component: () => import("../views/healthCare.vue"),
  },
  {
    path: "/doctor",
    name: "doctor",
    component: () => import("../views/doctor.vue"),
  },
  {
    path: "/learn/certificateManagement",
    name: "certificateManagement",
    component: () => import("../views/certificateManagement.vue"),
  },
  {
    path: "/learn/startTest",
    name: "startTest",
    component: () => import("../views/startTest.vue"),
  },
  {
    path: "/learn/continuEducation",
    name: "continuEducation",
    component: () => import("../views/continuEducation.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
